<template>
  <div
    v-if="product && impression"
    class="pro-coffee-detail pro-coffee-detail--has-impressions">
    <ProCoffeeHistoryDetail
      v-if="isProCoffee"
      :product="product"
      :impression="impression"
      @update="updateImpression" />
    <CoffeeHistoryDetail
      v-else
      :product="product"
      :impression="impression"
      @update="updateImpression" />
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import { useNativeConnection } from '@/composables/useNativeConnection';
const { setMileage } = useNativeConnection();
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    CoffeeHistoryDetail: () =>
      import('@/components/coffeeHistoryDetail/CoffeeHistoryDetail.vue'),

    ProCoffeeHistoryDetail: () =>
      import('@/components/coffeeHistoryDetail/ProCoffeeHistoryDetail.vue')
  },

  setup: (props, context) => {
    const product = ref(null);
    const impression = ref(null);
    const impressionId = computed(
      () => context.root.$route.params.impressionId
    );
    const isProCoffee = computed(() => product.value?.baristas.length > 0);

    const fetch = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('products')
          .getImpression(impressionId.value);
        impression.value = data;
        product.value = data.product;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const updateImpression = async (memo) => {
      try {
        context.root.$_loading_start();

        await context.root
          .$repositories('products')
          .updateImpression(impressionId.value, {
            is_favorable: impression.value?.is_favorable,
            memo
          });
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    // 感想登録でマイル付与されていた場合はネイティブ側で演出を行う
    const nowMileage = Number(context.root.$route.query.now_mileage || 0);
    const plusMileage = Number(context.root.$route.query.plus_mileage || 0);
    const isStageUp = Number(context.root.$route.query.stage_up) === 1;
    const isNotEmptyPresent =
      Number(context.root.$route.query.has_present) === 1;
    const stageNameBefore = context.root.$route.query.stage_name_before || null;
    const stageNameAfter = context.root.$route.query.stage_name_after || null;
    const stageNameNext = context.root.$route.query.stage_name_next || null;
    const waitForLoadComplete = false;

    if (plusMileage > 0) {
      setMileage({
        plus_mileage: plusMileage,
        is_stage_up: isStageUp,
        now_mileage: nowMileage,
        is_not_empty_present: isNotEmptyPresent,
        stage_name_before: stageNameBefore,
        stage_name_after: stageNameAfter,
        stage_name_next: stageNameNext,
        wait_for_load_complete: waitForLoadComplete
      });
    }

    fetch();

    return {
      WORDS,
      product,
      impression,
      isProCoffee,
      updateImpression
    };
  }
});
</script>

<style lang="scss" scoped>
.pro-coffee-detail {
  padding-bottom: 100px;

  &--no-map {
    padding-bottom: 64px;
  }

  &--has-impressions {
    padding-bottom: 24px;

    .pro-coffee-detail-impression-button {
      position: static;
      bottom: auto;
      width: auto;
    }
  }
}
</style>
